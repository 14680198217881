/*########## Style des composants communs ##########*/

/* Style de la navbar */
.navbar {
    background-color: #2e2f34;
    height: 100px;
    font-size: 20px;
}

.navbar .navbar-brand {
    font-size: 40px;
}

.navbar .nav-link,
.navbar .navbar-brand {
    color: white; /* Appliquer la couleur blanche au texte de la Navbar */
}
/* Style pour les liens lorsqu'ils sont survolés */
.navbar-nav .nav-link:hover,
.navbar .navbar-brand:hover,
.linkedin-link img:hover {
    color: rgb(218, 0, 0); /* Couleur de survol */
    transition: color 0.3s ease; /* Transition de couleur */
    transform: scale(1.05); /* Agrandissement de l'image au survol */
}

/* Style de la barre de progression */
.progress {
    height: '5px';
    background-color :#34353a;
}

/* Style de la barre de scroll */

::-webkit-scrollbar {
    width: 7px; /* Largeur de la barre de défilement */
  }
  
  /* Bouton de défilement de la barre de défilement **/
  ::-webkit-scrollbar-thumb {
    background-color: rgb(118, 118, 118); /* Couleur de fond du bouton de défilement */
    border-radius: 3px; /* Bord arrondi */
  }
  
  /* Défilement de la barre de défilement au survol */
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(218, 0, 0); /* Couleur de fond au survol */
  }
  
  /* Arrière-plan de la barre de défilement */
  ::-webkit-scrollbar-track {
    background-color: #34353a; /* Couleur de fond de la barre de défilement */
  }

/* Style du composant footer */

/* Style du footer */

.footer {
    background-color: #34353a;
    color: white;
    padding: 20px 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.col {
    flex: 1;
    padding: 0 15px;
}

.footer-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-item p {
    margin: 5px 0;
}

.footer-item h5 {
    margin-bottom: 10px;
}

.footer-item ul {
    list-style: none;
    padding: 0;
}

.footer-item ul li {
    margin-bottom: 5px;
}

.footer-item a {
    color: white;
    text-decoration: none;
}

.footer-item a:hover {
    color: #af0000;
}



/*########## Style des composants page1 ##########*/

.point {
    display: inline-block;
    width: 8px; /* taille du point */
    height: 8px; /* taille du point */
    background-color: rgb(218, 0, 0); /* couleur du point */
    border-radius: 50%; /* pour faire un cercle */
    margin-left: 5px; /* espace entre le texte et le point */
}

/* Style de la page de garde */
.mainpage {
    display: flex;
    background-color: #34353a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 99vh;
    padding-top: 105px;
}

.mainpage > div {
    flex: 1;
    text-align: left;
    margin-left: 100px;
    margin-right: 100px;
}

.redline {
    border: 4px solid rgb(218, 0, 0);
    opacity: 100%;
    width: 80%;
    border-radius: 50px;
}

.bouton {
    padding: 5px;
    color: rgb(218, 0, 0);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: solid 3px rgb(218, 0, 0);
    text-decoration: none;
}

.more {
    color: rgb(218, 0, 0);
    font-size: 20px;
    text-decoration: none;
}

/* Style de la section présentation */

.presentation {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 90vh;
}


.content {
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 800px; /* ou toute autre largeur souhaitée */
    padding: 20px;
    text-align: center;
}

.content > * {
    margin-bottom: 20px; /* Ajoute un espacement entre les éléments */
}


.spacer {
    position: absolute;
    visibility: hidden; /* Rend le point d'ancrage invisible */
}



.icons-column {
  display: flex;
  flex-direction: column;
  gap: 50px; /* Espacement entre les groupes d'icônes */
}

.icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
    width: 9vh;
    height: 9vh;
    margin: 0 20px; /* Espacement horizontal entre les icônes à l'intérieur d'un conteneur */
    transition: transform 0.3s ease;
}

.icon:hover {
  transform: scale(1.2);
}

.skills-title {
    font-size: 50px;
    margin: 0 40px;
}



/* Style de la section projets */

.projets {
    display: flex;
    flex-direction: column;
    background-color: #34353a;
    color: white;
    height: 90vh;
    justify-content: center; /* Pour centrer les éléments horizontalement */
    align-items: center; /* Pour centrer les éléments verticalement */
}

.projets > h1 {
    margin-bottom: 50px;
}

.liste_projets {
    display: flex;
    flex-wrap: wrap; /* Pour que les divs se mettent à la ligne */
    justify-content: center; /* Pour centrer les éléments horizontalement */
    align-items: center; /* Pour centrer les éléments verticalement */
}

.projet {
    display: flex; /* Pour aligner le contenu horizontalement */
    justify-content: space-evenly; /* Pour centrer le contenu horizontalement */
    flex-direction: column; /* Pour aligner le contenu verticalement */
    align-items: center; /* Pour centrer le contenu verticalement */
    color: white;
    background-color: #2e2f34;
    margin: 50px; /* Marge entre les divs */
    width: 35vh; /* Largeur des divs */
    height: 35vh; /* Hauteur des divs */
    text-align: center; /* Centrer le texte */
    text-decoration: none;
}

/* Style de la section contact */

.contacts > div,
.contacts > a > div{
    display: flex;
    flex-direction: row; /* Aligne les éléments horizontalement */
    justify-content: center;
    align-items: center;
    background-color: #34353a;
    color: rgb(218, 0, 0);
    width: 30vh;
    height: 10vh;
    margin: 20px;
}

.contacts > div > *,
.contacts > a > div > *{
    margin: 0 10px; /* Ajoute un espacement horizontal de 10px entre les éléments */
}


.contact {
    display: flex;
    flex-direction: row; /* Assure que les éléments sont alignés horizontalement */
    justify-content: space-evenly;
    align-items: center;
    background-color: #2e2f34;
    color: white;
    height: 90vh;
}

.contact > * {
    margin: 10px; /* Ajoute un espacement de 10px autour de chaque élément */
}




/*style du formulaire*/
form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #34353a;
    color: white;
    padding: 50px;
    border-radius: 10px;
}

input {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

textarea {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
}


/*########## Style des composants page2 ##########*/

/* Style de la section BTS Présentation */

.BTS {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #34353a;
    color: white;
    height: 100vh;
}

/* Style de la section BTS project-selector */

.project-selector {
    display: flex;
    justify-content: center;
    height: 10vh;
    background-color: #2e2f34;
}

.project-selector button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #ffffff; /* gris */
    position: relative;
}

.project-selector button:hover {
    color: rgb(218, 0, 0); /* gris légèrement plus foncé au survol */
}

.project-selector button::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(218, 0, 0); /* couleur du soulignement */
    transform: scaleX(0); /* masqué par défaut */
    transition: transform 0.3s ease; /* animation de transition */
}

.project-selector button.selected::after {
    transform: scaleX(1); /* afficher le soulignement pour le projet sélectionné */
}

.slide-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.slide-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.5s, transform 0.5s;
}

.slide-exit {
    opacity: 1;
    transform: translateX(0%);
}

.slide-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s, transform 0.5s;
}



/* Style de la section BTS TardyGrade */

.TardyGrade {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 80vh;
}

/* Style de la section BTS Dragonium */

.Dragonium {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 80vh;
}

/* Style de la section BTS DCH */

.DCH {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 80vh;
}

/* Style de la section BTS Veille techno */

.VeilleTechno {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #34353a;
    color: white;
    height: 90vh;
}

.VeilleTechno-content {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    padding: 20px;
    text-align: center;
}

hr {
    border: 2px solid #555;
    opacity: 100%;
    width: 100%;
    border-radius: 50px;
}

.Articles {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 90vh;
}

.Articles-content {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    padding: 2vh;
    text-align: center;
}

.article-container {
    display: flex;
    overflow-x: auto;
    width: 100%;
}

.article {
    background-color: #34353a;
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 50vh; /* ou toute autre largeur souhaitée */
    max-height: 100vh;
    text-align: center;
    margin: 0 2vh;
    flex: 0 0 auto;
    margin-right: 20px;
}


.article-details {
    padding: 10px; /* Ajoute un espacement autour des détails de l'article */
}

.article-title {
    font-size: 3vh; /* Taille de police pour le titre de l'article */
}

.article-description {
    font-size: 2vh; /* Taille de police pour la description de l'article */
    margin: 0; /* Espacement supplémentaire en dessous de la description */
}

.article-link {
    width: 20vh;
    margin: 10px;
}

/* Style de la section BTS Dossier E4 */

.E4 {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #34353a;
    color: white;
    height: 90vh;
}

.E4-content {
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 800px; /* ou toute autre largeur souhaitée */
    padding: 20px;
    text-align: center;
}

.E4-elements {
    display: flex;
    justify-content: space-between; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
    margin: 20px; /* Ajoute un espacement autour des éléments */
}

.E4-element {
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 300px; /* ou toute autre largeur souhaitée */
    padding: 20px;
    text-align: center;
}

/* Style de la section BTS Dossier E5 */

.E5 {
    display: flex;
    justify-content: center; /* Centre les éléments verticalement */
    align-items: center; /* Centre les éléments horizontalement */
    background-color: #2e2f34;
    color: white;
    height: 90vh;
}

.E5-content {
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 800px; /* ou toute autre largeur souhaitée */
    padding: 20px;
    text-align: center;
}

.E5-elements {
    display: flex;
    justify-content: space-between; /* Centre les éléments horizontalement */
    align-items: center; /* Centre les éléments verticalement */
    margin: 20px; /* Ajoute un espacement autour des éléments */
}

.E5-element {
    display: flex;
    flex-direction: column; /* Place les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    max-width: 300px; /* ou toute autre largeur souhaitée */
    padding: 20px;
    text-align: center;
}